import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { colors } from "../lib/theme";
import PreviewCard from "./PreviewCard";
import { Link } from "react-router-dom";
import ShowGeneratedQuestions from "./ShowGeneratedQuestions";
import QuestionnaireForm from "./QuestionnaireForm";
import { Helmet } from "react-helmet";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Questionnaire = ({ config }) => {
  const urlLocation = useLocation();
  const navigate = useNavigate();
  window.scrollTo(0, 0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [generatedQuestions, setGeneratedQuestions] = useState([]);
  const [cardTitle, setCardTitle] = useState("");
  const [cardMessage, setCardMessage] = useState("");
  const [senderClosing, setSenderClosing] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [uploadedImage, setUploadedImage] = useState(null);
  const [openaiApiKey, setOpenaiApiKey] = useState("");
  const [relationship, setRelationship] = useState("");
  const [occasion, setOccasion] = useState("");
  const [location, setLocation] = useState("US");
  const [favoriteMemory, setFavoriteMemory] = useState("");
  const [hobbiesInterests, setHobbiesInterests] = useState("");
  const [showQuestions, setShowQuestions] = useState(false);
  const [newQuestion, setNewQuestion] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [shouldUpdateQuestions, setShouldUpdateQuestions] = useState(false);

  useEffect(() => {
    if (config?.openaiApiKey) {
      setOpenaiApiKey(config.openaiApiKey);
    } else {
      console.error("OpenAI API key is missing from the configuration");
      setError("OpenAI API key is missing. Please check your configuration.");
    }

    // Parse URL parameters
    const params = new URLSearchParams(urlLocation.search);
    const relationshipParam = params.get('relationship');
    const occasionParam = params.get('occasion');
    const memoryParam = params.get('memory');
    const interestsParam = params.get('interests');

    setRelationship(relationshipParam);
    setOccasion(occasionParam);
    setFavoriteMemory(memoryParam);
    setHobbiesInterests(interestsParam);
    setShouldUpdateQuestions(true);
  }, [config, urlLocation.search]);

  useEffect(() => {
    if (shouldUpdateQuestions) {
      if (relationship || occasion || favoriteMemory || hobbiesInterests) {
        updateQuestions();
      } else {
        console.error("State not fully updated before calling updateQuestions");
      }
      setShouldUpdateQuestions(false);
    }
  }, [relationship, occasion, favoriteMemory, hobbiesInterests, shouldUpdateQuestions]);

  const handleQuestionChange = (id, newText) => {
    setGeneratedQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === id ? { ...q, text: newText } : q))
    );
  };

  const handleRemoveQuestion = (id) => {
    setGeneratedQuestions((prevQuestions) =>
      prevQuestions.filter((q) => q.id !== id)
    );
  };

  const handleAddQuestion = () => {
    if (newQuestion.trim()) {
      setGeneratedQuestions((prevQuestions) => [
        ...prevQuestions,
        { id: Date.now(), text: newQuestion.trim() },
      ]);
      setNewQuestion("");
    }
  };

  const updateQuestions = async () => {
    window.scrollTo(0, 0);
    setLoading(true);
    setError(null);
    try {
      if (!openaiApiKey) {
        throw new Error("OpenAI API key is missing");
      }
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o-2024-08-06",
          messages: [
            {
              role: "user",
              content: `We're creating a gift for ${relationship} that requires the recipient to answer 15 questions about themselves on the occasion of ${occasion}.
                Generate 15 questions for a ${relationship}.
                Please only generate 15 questions.  You should count them to ensure that there are only 15.
                Basic instructions: should include 15 questions that are core to interview and 5 that are specific/relevant to the additional context of the user's favorite memory of ${favoriteMemory} and their interest in ${hobbiesInterests}. We can infer if they're a Dad, that they have at least one kid.
                1. A very short title for the gift card (format: "Title: [your generated title]").
                2. A heartfelt message for the gift card (format: "Message: [your generated message]"). Don't add opening and closings (ie. "Dearest [so-and-so]" and "Yours truly [so-and-so]").
                3. 15 questions to capture key moments from their life and learn more about them as a person (format: "Questions: 1. [question1] 2. [question2] ...").
                ${
                  additionalDetails
                    ? `- Additional details: ${additionalDetails}`
                    : ""
                }
                Here are some of our favorite starter questions to ask, you should try to include some of these:
                - What is your full name and why were you named that?
                - Where and when were you born?
                - How would you describe your childhood?
                - Who were the most influential people in your early life?
                - What were your aspirations as a young person?
                - How did you meet your spouse/partner?
                - What have been the keys to a successful relationship in your opinion?
                - What was your profession and how did you choose it?
                - What did you find most rewarding about your career?
                - Are there any accomplishments you are particularly proud of?
                - What hobbies have you enjoyed?
                - Have you traveled much? What were some of your favorite places?
                - What values are most important to you?
                - What do you feel most grateful for?
                - What are some of the most important lessons you've learned in life?
                - How would you like to be remembered?
                - What are you most looking forward to right now?
                - Are there any goals or dreams you still wish to achieve?
                - Do you have a philosophy of life that guides your actions and decisions?
                - What's one thing you think everyone should try at least once in their life?
                ,`
            },
          ],
          temperature: 0.5,
        },
        {
          headers: {
            Authorization: `Bearer ${openaiApiKey}`,
            "Content-Type": "application/json",
          },
        }
      );

      const content = response.data.choices[0].message.content;

      // Extract title
      const titleMatch = content.match(/Title: (.*)/);
      const title = titleMatch
        ? titleMatch[1].replace(/"/g, "")
        : `Happy ${occasion}, ${relationship}!`;
      setCardTitle(title);

      // Extract message
      const messageMatch = content.match(/Message: ([\s\S]*?)(?=Questions:)/);
      const message = messageMatch
        ? messageMatch[1].trim().replace(/"/g, "")
        : "";
      setCardMessage(message);

      // Extract and process questions
      const questionsMatch = content.match(/Questions:([\s\S]*)/);
      const questionsContent = questionsMatch ? questionsMatch[1] : "";
      const questions = questionsContent
        .split(/\d+\.\s/)
        .filter((q) => q.trim() !== "")
        .map((q, index) => ({
          id: index,
          text: q.trim(),
        }));

      setGeneratedQuestions(questions);
      setShowQuestions(true);
    } catch (error) {
      console.error("Error generating content:", error);
      setError(
        "Failed to generate content. Please check your API key and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const handleShowPreview = () => {
    setShowPreview(true);
    window.scrollTo(0, 0);
  };

  if (loading) {
    return (
      <Card
        sx={{
          maxWidth: 600,
          margin: "auto",
          mt: 4,
          border: `1px solid ${colors.lightGray}`,
          background: "none",
          color: colors.white,
          borderRadius: "20px",
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <CircularProgress sx={{ color: colors.gold }} />
          </Box>
          <Typography variant="body1" align="center">
            Generating personalized questions...
            <br />
            Just a few more seconds!
          </Typography>
        </CardContent>
      </Card>
    );
  }

  if (error) {
    return (
      <Card sx={{ maxWidth: 600, margin: "auto", mt: 4 }}>
        <CardContent>
          <Typography variant="h6" color="error" gutterBottom>
            {error}
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setError(null);
            }}
          >
            Start Over
          </Button>
        </CardContent>
      </Card>
    );
  }

  if (showPreview) {
    return (
      <PreviewCard
        cardTitle={cardTitle}
        setCardTitle={setCardTitle}
        cardMessage={cardMessage}
        setCardMessage={setCardMessage}
        senderClosing={senderClosing}
        setSenderClosing={setSenderClosing}
        uploadedImage={uploadedImage}
        setUploadedImage={setUploadedImage}
        handlePrevious={() => setShowPreview(false)}
        generatedQuestions={generatedQuestions}
        additionalDetails={additionalDetails}
        hobbiesInterests={hobbiesInterests}
        favoriteMemory={favoriteMemory}
        location={location}
        relationship={relationship}
      />
    );
  }

  if (showQuestions) {
    return (
      <ShowGeneratedQuestions
        generatedQuestions={generatedQuestions}
        additionalDetails={additionalDetails}
        setAdditionalDetails={setAdditionalDetails}
        handlePrevious={() => setShowQuestions(false)}
        handleQuestionChange={handleQuestionChange}
        handleRemoveQuestion={handleRemoveQuestion}
        newQuestion={newQuestion}
        setNewQuestion={setNewQuestion}
        handleAddQuestion={handleAddQuestion}
        updateQuestions={updateQuestions}
        handleShowPreview={handleShowPreview}
      />
    );
  }

  return (
    <Box sx={{ maxWidth: 800, margin: "auto" }}>
      <Helmet>
        <title>Create Your Gift - Golden Record</title>
        <meta name="description" content="Create a personalized audio gift with Golden Record" />
      </Helmet>
      <Typography variant="h3" sx={{ color: colors.gold, mb: 2 }}>
        Who are you creating a Golden Record with?
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: colors.white, fontSize: "1.3rem", mb: 4 }}
      >
        Are you ready to learn more about someone through their stories?
        We'll use this information to generate a personalized gift card
        just for them, inviting them to share their stories with you.
        Not ready to create your gift yet?{` `}
        <div>
          <Link
            to="/register"
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginTop: "0.5rem",
              color: colors.gold,
              textDecoration: "none"
            }}
          >
            Skip this and create an account for yourself!
            <ArrowForwardIcon sx={{ marginLeft: '0.5rem' }} />
          </Link>
        </div>
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2, backgroundColor: colors.darkerNavy, padding: 4, borderRadius: "10px", mb: 4 }}>
        <QuestionnaireForm
          relationship={relationship}
          setRelationship={setRelationship}
          occasion={occasion}
          setOccasion={setOccasion}
          location={location}
          setLocation={setLocation}
          favoriteMemory={favoriteMemory}
          setFavoriteMemory={setFavoriteMemory}
          hobbiesInterests={hobbiesInterests}
          setHobbiesInterests={setHobbiesInterests}
        />
      </Box>

      <Button
        variant="secondary"
        onClick={updateQuestions}
        sx={{ mt: 2 }}
      >
        Generate Questions
      </Button>
    </Box>
  );
};

export default Questionnaire;
